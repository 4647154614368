import { localizationService } from '@shared-services/localization-service';
import {
    ProductPurchaseOptionType,
    ProductType,
    UIProductPrices,
} from './types';
import { logger } from '@shared-services/log-service';
import { PRODUCT_ONE_TIME_PAYMENT_PLAN_ID } from '../consts';

export function encodeUniqueKey(variation: { [key: string]: string } | string) {
    try {
        const txtVariation =
            typeof variation === 'string'
                ? variation
                : JSON.stringify(variation);
        if (window.TextEncoder) {
            const encodedArray = new TextEncoder().encode(txtVariation);
            const encodedVariation = String.fromCharCode(...encodedArray);
            return btoa(encodedVariation);
        }
        return btoa(txtVariation);
    } catch (err) {
        logger.warn({
            err,
            variation,
            tags: ['encodeUniqueKey'],
        });
        return '{}';
    }
}

export function decodeUniqueKey(base64Key: string) {
    try {
        return JSON.parse(
            new TextDecoder().decode(
                Uint8Array.from(atob(base64Key), (m) => m.charCodeAt(0))
            ) || '{}'
        );
    } catch (err) {
        return {};
    }
}

export function createProductPaymentPlanOptionList(product: ProductType) {
    const {
        purchase_options: purchaseOptions = [],
        payment_plans: paymentPlans = [],
    } = product;

    switch (true) {
        case purchaseOptions.length === 2:
            return [
                {
                    id: PRODUCT_ONE_TIME_PAYMENT_PLAN_ID,
                    name: localizationService.str(
                        'ui.runtimessr.priceOptions.option.oneTimeOnly'
                    ),
                    discount_percentage: 0,
                },
                ...paymentPlans,
            ];
        case purchaseOptions.includes(ProductPurchaseOptionType.SUBSCRIPTION):
            return paymentPlans;
        default:
            return [];
    }
}

export const calculateProductDiscountPrice = ({
    price,
    displayedPrice,
    discountPercentage,
}: UIProductPrices & {
    discountPercentage?: number;
}) => {
    if (!discountPercentage) {
        return {
            price,
            displayed_price: displayedPrice,
        };
    }

    const fractionDigits = 2;

    // calculation the same as on the product page
    const priceAfterDiscount = price - (price * discountPercentage) / 100;
    const absPrice = Math.abs(priceAfterDiscount);
    const updatedPrice =
        Math.trunc(absPrice * Math.pow(10, fractionDigits)) /
        Math.pow(10, fractionDigits);

    const updatedDisplayedPrice = displayedPrice.replace(
        price.toFixed(fractionDigits),
        updatedPrice.toFixed(fractionDigits)
    );

    return {
        price: +updatedPrice,
        displayed_price: updatedDisplayedPrice,
    };
};
