import React from 'react';
import withAddToCart from './withAddToCart';
import { RuntimeWidget } from 'client/types';
import Button from 'client/widget-components/components/Button';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import {
    getWidgetIconProps,
    getWidgetStyles,
} from './migration/addToCartMigration';
import { ButtonStyles } from 'client/widget-components/CommonModelTypes';
import { localizationService } from '@shared-services/localization-service';
import { AddToCartStyleTypes, RuntimeAddToCartProps } from './types';

export const RuntimeAddToCart: RuntimeWidget<RuntimeAddToCartProps> = ({
    text,
    disabledText,
    _styles,
    iconProps,
    isDisabled,
    showButtonHoverEffect,
    showButtonDisabledEffect,
    isSubscribe,
}: RuntimeAddToCartProps) => {
    const { showIcon, iconName } = getWidgetIconProps(iconProps);
    const styles = getButtonStyles(_styles);

    const buttonText =
        (isDisabled
            ? disabledText
            : isSubscribe
            ? localizationService.str('ui.runtimessr.addtocart.subscribe.title')
            : text) || localizationService.str('widget.addtocart.title');

    return (
        <Button
            styles={styles}
            buttonContent={{
                text: buttonText,
            }}
            dataGrab='add-to-cart-button'
            iconClass={showIcon && iconName}
            isDisabled={showButtonDisabledEffect || isDisabled}
            className='ssr-button'
            showHoverEffect={showButtonHoverEffect}
        />
    );
};

function getButtonStyles(styles?: AddToCartStyleTypes): ButtonStyles {
    const stylesProps: ButtonStyles = getWidgetStyles(styles);
    return {
        root: [buttonDefaultStyles, stylesProps?.root],
        rootHover: stylesProps?.rootHover,
        rootDisabled: [disabledDefaultStyles, stylesProps?.rootDisabled],
        text: stylesProps?.text,
        rootHoverText: stylesProps?.rootHoverText,
        disabledText: stylesProps?.disabledText,
        icon: stylesProps?.icon,
        rootHoverIcon: stylesProps?.rootHoverIcon,
        iconDisabled: stylesProps?.iconDisabled,
        buttonTypeCSSClass: stylesProps?.buttonTypeCSSClass,
    };
}

const buttonDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: '32px',
        padding: '8px 14px',
        minWidth: 'fit-content',
        textAlign: 'center',
        boxSizing: 'border-box',
        margin: 0,
    },
};

const disabledDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        opacity: 0.6,
    },
};

export type { RuntimeAddToCartProps };
export default withAddToCart(RuntimeAddToCart);
